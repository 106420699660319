<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="300">

      <v-card>

        <v-card-title>
          Animateurs
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-0">

          <v-list dense>
            <template v-for="(item, index) in animators">
              <v-list-item :key="index" link>

                <v-list-item-avatar v-if="item.photo" left size="40">
                  <v-img class="zoom-pointer"
                         @click="$refs.lightbox.open(item.photo)"
                         :src="$baseUrl + item.photo"></v-img>
                </v-list-item-avatar>

                <v-list-item-avatar v-else
                                    :class="$func.randomColor()"
                                    class="font-weight-medium"
                                    left
                                    size="40"
                >
                  {{ $func.avatarName(item.name) }}
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.phone"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider
                      v-if="index < animators.length - 1"
                      :key="'key-'+index"
              ></v-divider>
            </template>
          </v-list>

        </v-card-text>
      </v-card>
    </v-dialog>

    <Lightbox ref="lightbox"/>

  </div>
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
            animators: []
        }
    },
    methods: {
        open(animators) {
            this.animators = animators
            this.dialog = true
        },
    },
}
</script>

<style scoped>

</style>