<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="900">

      <v-card :disabled="saveLoading" :loading="saveLoading">

        <v-card-title>
          {{ title }}
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-10">

          <v-row>

            <v-col class="text-center" cols="6">
              <img :src="require('@/assets/account.gif')" alt="" width="100%">
            </v-col>

            <v-col cols="6">

              <v-autocomplete v-model="form.animator_ids"
                              :disabled="isLoading"
                              :error-messages="errors.animator_ids"
                              :items="animators"
                              :loading="isLoading"
                              chips
                              class="autocomplete"
                              dense
                              item-text="name"
                              item-value="id"
                              label="Animateurs(optional)"
                              multiple
                              outlined
                              prepend-inner-icon="mdi-account-supervisor-circle-outline"
                              small-chips
              >
                <template v-slot:selection="data">
                  <v-chip :input-value="data.selected"
                          :style="{margin : '2px'}"
                          close
                          small
                          v-bind="data.attrs"
                          @click="data.select"
                          @click:close="remove(data.item)"
                  >

                    <v-avatar v-if="data.item.photo" left>
                      <v-img :src="$baseUrl + data.item.photo"
                             class="zoom-pointer"
                             @click="$refs.lightbox.open(data.item.name)"></v-img>
                    </v-avatar>

                    <v-avatar v-else
                              class="font-weight-medium bg-primary-subtle primary--text"
                              left
                    >
                      {{ $func.avatarName(data.item.name) }}
                    </v-avatar>

                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>

                    <v-list-item-avatar v-if="data.item.photo">
                      <v-img :src="$baseUrl + data.item.photo"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-avatar v-else :class="$func.randomColor()"
                                        class="font-weight-medium"
                    >
                      {{ $func.avatarName(data.item.name) }}
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

              <v-text-field v-model="form.name"
                            :error-messages="errors.name"
                            dense
                            label="Nom *"
                            outlined>
              </v-text-field>

              <v-text-field v-model="form.phone"
                            :error-messages="errors.phone"
                            dense
                            label="Numéro de téléphone *"
                            outlined>
              </v-text-field>


              <v-text-field v-model="form.email"
                            :error-messages="errors.email"
                            dense
                            label="Email *"
                            outlined
                            type="email">
              </v-text-field>

              <v-text-field v-model="form.password"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :error-messages="errors.password"
                            :type="show ? 'text' : 'password'"
                            dense
                            label="Mot de passe..."
                            outlined
                            prepend-inner-icon="mdi-lock-outline"
                            @click:append="show = !show"></v-text-field>

              <v-file-input ref="image"
                            :error-messages="errors.image"
                            accept="image/*"
                            chips
                            counter
                            dense
                            label="Photo"
                            outlined
                            show-size
                            small-chips
                            @change="uploadImage"
                            @click:clear="resetImage">
              </v-file-input>

              <v-switch v-model="form.is_active" color="secondary" hide-details label="Compte active"/>

            </v-col>
          </v-row>


        </v-card-text>

        <v-divider/>

        <v-card-actions class="grey lighten-4">
          <v-spacer/>
          <v-btn :loading="saveLoading"
                 color="secondary"
                 depressed
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            isLoading: false,
            animators: [],

            show: false,
            title: '',
            dialog: false,
            saveLoading: false,
            errors: {},
            form: {},
        }
    },
    methods: {
        uploadImage(file) {
            if (file) {
                const reader = new FileReader()
                reader.onloadend = () => {
                    this.form.image = reader.result
                }
                reader.readAsDataURL(file)
            }
        },
        open(item, title) {
            this.getAnimators()
            this.resetImage()
            this.errors = {}
            this.dialog = true
            this.form = {
                id: item ? item.id : '',
                name: item ? item.name : '',
                phone: item ? item.phone : '',
                email: item ? item.email : '',
                is_active: item ? item.is_active : true,
                password: null,
                image: '',
                address: item ? item.address : '',

                animator_ids: item ? item.animators.map(el => el.id) : [],
            }
            this.title = title
        },
        save() {
            this.saveLoading = true
            this.errors = {}

            HTTP({
                url: this.form.id ? '/supervisors/' + this.form.id : '/supervisors',
                method: this.form.id ? 'put' : 'post',
                data: this.form,
            }).then(() => {
                this.dialog = false
                this.$emit('refresh')
                this.$successMessage = 'Ce superviseur a été enregistré avec succès.'
                this.saveLoading = false
            }).catch(err => {
                this.saveLoading = false
                this.errors = err.response.data.errors
                console.log(err)
            })
        },
        resetImage() {
            if (this.$refs.image) {
                this.$refs.image.reset()
            }
            this.form.image = ''
        },
        getAnimators() {
            this.isLoading = true
            HTTP.get('animators').then(res => {
                this.animators = res.data.data
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        remove(item) {
            const index = this.form.animator_ids.indexOf(item.id)
            if (index >= 0) this.form.animator_ids.splice(index, 1)
        },
    },
}
</script>

<style scoped>

</style>