<template>
  <div>
    <v-container fluid>

      <Overlay :overlay="overlay"/>

      <v-row>
        <v-col cols="12">
          <v-card class="shadow rounded-lg">

            <v-card-title class="pa-0">
              <RolesTab/>
            </v-card-title>

            <v-divider/>

            <TableView :is-loading="isLoading"
                       :supervisors.sync="supervisors"
                       @delete="deleteSupervisor($event)"
                       @refresh="getSupervisors"
                       @change-status="changeStatus($event)"/>

          </v-card>
        </v-col>
      </v-row>

      <ConfirmDialog ref="confirmDialog"/>

    </v-container>
  </div>
</template>

<script>
import TableView from "@/views/supervisors/components/TableView.vue";
import {HTTP} from "@/http-common";
import RolesTab from "@/components/views/RolesTab.vue";

export default {
    components: {RolesTab, TableView},
    data() {
        return {
            overlay: false,
            isLoading: false,
            supervisors: [],
            filter: {}
        }
    },

    methods: {
        getSupervisors() {
            this.isLoading = true
            HTTP.get('/supervisors', {
                params: {
                    filter: this.filter
                }
            }).then((res) => {
                this.isLoading = false
                this.supervisors = res.data.data
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        async deleteSupervisor(event) {
            if (await this.$refs.confirmDialog.open('', '', {
                icon: '',
                agreeText: '',
                color: 'red',
            })) {
                /**
                 * array
                 */
                let index = event[1]
                let id = event[0]

                this.overlay = true
                HTTP.delete('supervisors/' + id).then(() => {
                    this.supervisors.splice(index, 1)
                    this.overlay = false
                    this.$successMessage = 'Ce superviseur a été supprimé avec succès.'
                }).catch(err => {
                    this.overlay = false
                    console.log(err)
                })
            }
        },
        async changeStatus(userId) {
            if (await this.$refs.confirmDialog.open('', 'Êtes-vous sûr de vouloir changer le statut de ce superviseur ?', {
                icon: 'mdi-backup-restore',
                agreeText: 'Changer',
                color: 'orange',
            })) {
                this.overlay = true
                HTTP.post('/supervisors/' + userId + '/change-status').then(() => {
                    this.overlay = false
                    this.getSupervisors()
                    this.$successMessage = 'Ce superviseur a été modifié avec succès.'
                }).catch(err => {
                    this.overlay = false
                    console.log(err)
                })
            }
        },
        onPageChange() {
            this.getSupervisors();
        },
    },
    created() {
        this.getSupervisors()
    },
    watch: {
        view() {
            this.getSupervisors();
        },
    },
}
</script>

<style scoped>
.v-tab {
    min-width: 30px;
}
</style>